<!-- 定义模版对象 -->
<template>
  <div class="login">
    <div class="login-form">
      <div class="login-form-welcome">
        <div class="login-form-welcome-pic">
          <el-image :src="require('../../assets/imgs/welcome.png')" style="width:265px;height:47px" lazy></el-image>
        </div>
        <div class="login-form-welcome-desc font-16">
          看数据上嗨数
        </div>
      </div>
      <div class="login-form-form">
        <div class="login-form-form-type">
          <div :class=" active == 1 ?  'login-form-form-type login-form-form-type-choose font-15' : 'login-form-form-type font-15' " @click="changeLogin(1)">
            扫码登录
          </div>
          <div :class=" active == 2 ?  'login-form-form-type login-form-form-type-choose font-15' : 'login-form-form-type font-15' " @click="changeLogin(2)">
            手机登录
          </div>
        </div>
        <div v-if="active == 1" v-loading="loading">
          <!-- <qrCode :url='qr_url' :logoUrl='logo_url'></qrCode> -->
          <div class="login-form-form-qr">
            <div class="login-form-form-qr-title font-24">
              扫码登录
            </div>
            <div class="login-form-form-qr-desc font-20">
              打开微信扫码
            </div>
            <div class="login-form-form-qr-pic">
              <img :src="qr_url" style="width: 200px;height:200px;position: relative;" alt="加载中">
              <!--  <img :src="logo_url"  class="login-form-form-qr-logo" alt=""> -->
              <!-- <qrCode :logoUrl="logo_url" :url="qr_url"></qrCode> -->
              </img>
            </div>
          </div>

        </div>
        <div v-if="active == 2">
          <el-alert title="由于第一次扫码登录，请先绑定手机号" type="warning" v-if="showTip"></el-alert>
          <div :class=" showTip ? ' login-form-form-content margin-top-30' : ' login-form-form-content margin-top-60' ">
            <div class="login-form-form-content-main">
              <el-form ref="loginForm" label-width="0" :rules="rules" class="login_form" :model="loginForm" @submit.native.prevent>
                <el-form-item prop="mobile">
                  <el-input prefix-icon="iconfont iconuser" v-model="loginForm.mobile" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <div class="login-form-form-content-code">

                    <el-input prefix-icon="iconfont iconsuo" v-model="loginForm.code" placeholder="请输入验证码">
                      <el-button slot="append" :disabled='btnClick' @click="sendCode" class="font-12">{{btnText}}</el-button>
                    </el-input>
                  </div>
                </el-form-item>
                <el-form-item class="btns">
                  <el-button type="primary" style="width: 100%; background-color: #4975E9 " @click="loginByMobile" native-type="submit">登录</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="login-form-form-content-bottom">
              <div class="login-form-form-content-bottom-desc font-10">
                未注册用户将自动注册
              </div>
              <div>
                <el-checkbox v-model="checked" class="login-form-form-content-bottom-check">
                  <div class="login-form-form-content-bottom-check-desc font-11">
                    登录代表您同意
                    <span class="login-form-form-content-bottom-check-desc-xieyi" @click.prevent="getXieyi(2)">用户协议</span>
                    和
                    <span class="login-form-form-content-bottom-check-desc-xieyi" @click.prevent="getXieyi(1)">隐私协议</span>
                  </div>
                </el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- 定义JS变量 -->
<script >
import { sendVerifyCode, loginByMobile, getQrcodeTicket, checkSubscribe, getUserInfo } from '../../api/api'
import qrCode from '../../components/qr_code.vue'
let T;
export default {
  data() {
    return {
      active: 2,
      loginForm: {
        mobile: '',
        password: ''
      },
      checked: true,
      btnClick: false,
      btnText: '发送验证码',
      rules: {
        mobile: [
          /* required 是否为必填项  message:提示信息 trigger:出发条件 */
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ],
        password: [
          /* required 是否为必填项  message:提示信息 trigger:出发条件 */
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ],
        code: [
          /* required 是否为必填项  message:提示信息 trigger:出发条件 */
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ]
      },
      qr_url: '',
      logo_url: require('../../assets/images/logo.png'),
      eventKey: '',
      loading: false,
      showTip: false,
    }
  },
  components: {
    qrCode,
  },
  created() {
    this.autoLogin();
  },
  mounted() {
    // this.loginByWX()
  },
  methods: {
    getXieyi(data) {
      console.log('getXieyi', data);
      const url = process.env.VUE_APP_MANAGE_URL + "/permission?type=" + data;
      window.open(url, '_blank');
    },
    async autoLogin() {
      let token = localStorage.getItem('h5_token');
      console.log('token', token);
      if(token) {
        const { data: res } = await getUserInfo();
        if(res.code == '0000') {
          const { data } = res;
          const { user, userThird } = data
          console.log('confirmLoginByMobile', res, data);
          localStorage.setItem("h5_token", data.sessionId)
          localStorage.setItem("token", user.sessionId)
          localStorage.setItem("user", JSON.stringify(user))
          this.$message.success("登录成功!");
          //5.登录成功之后,跳转到/home页面中
          this.$router.push({
            path: '/home',
            query: {
            }
          })
        }
      }
    },
    async toCheckSubscribe() {
      const { data: res } = await checkSubscribe(this.eventKey);
      console.log('toCheckSubscribe', res);
      if(res.code == '0000') {
        const { data } = res;
        const { user, userThird } = data
        console.log('confirmLoginByMobile', data);
        if(user) {
          this.$message.success("登录成功!")
          localStorage.setItem("h5_token", data.sessionId)
          localStorage.setItem("token", user.sessionId)
          localStorage.setItem("user", JSON.stringify(user))
          window.sessionStorage.setItem("activeMenu", '/home')
          window.sessionStorage.setItem("defaultActivename", '首页')
          //5.登录成功之后,跳转到/home页面中
          // this.$router.push("/index")
          this.$router.push({
            path: '/home',
            query: {
            }
          })
        } else {
          sessionStorage.setItem('open_id', userThird.thirdId)
          this.showTip = true;
          this.changeLogin(2);
        }
      } else if(res.code == '1002') {
        T = setTimeout(() => {
          this.toCheckSubscribe();
        }, 1000);
      } else {
        this.$message.error('查询用户是否关注公众号失败')
      }
    },
    async toGetQrcodeTicket() {
      const { data: res } = await getQrcodeTicket();
      console.log('getQrcodeTicket', res);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      if(res.code == '0000') {
        const { data } = res;
        this.qr_url = data.url;
        this.eventKey = data.eventKey;
        this.toCheckSubscribe()
      } else {
        this.$message.error('获取二维码失败')
      }
    },
    changeLogin(data) {
      this.active = data;
      this.loginForm = { tel: '', code: '' };
      this.loading = true;
      if(data == 1) {
        this.toGetQrcodeTicket();
        this.showTip = false;
        sessionStorage.setItem('open_id', '')
      } else {
        window.clearTimeout(T);
      }
    },
    sendCode() {
      const { mobile } = this.loginForm;
      console.log('sendCode', mobile);
      if(!mobile) {
        this.$message.error('请输入手机号')
        return;
      }
      // 定义手机号正则表达式  
      const phoneRegex = /^1[34578]\d{9}$/;

      // 使用test方法检查手机号是否符合格式  
      if(phoneRegex.test(mobile)) {
        this.confirmSend(mobile);
      } else {
        this.$message.error('请输入正确的手机号')
        return;
      }
    },
    timeOutSend(time) {
      let now = time - 1;
      if(now > 0) {
        setTimeout(() => {
          this.timeOutSend(now);
          this.btnText = '重新发送(' + now + ')'
        }, 1000);
      } else {
        setTimeout(() => {
          this.btnText = '发送验证码'
          this.btnClick = false;
        }, 1000);
      }
    },
    async confirmSend(mobile) {
      let param = {};
      param["mobile"] = mobile;
      const { data: res } = await sendVerifyCode(param);
      this.btnClick = true;
      this.timeOutSend(60);
      //   console.log('confirmSend',res);
    },
    loginByMobile() {
      this.$refs['loginForm'].validate((valid) => {
        if(valid) {
          this.confirmLoginByMobile()
        } else {
          this.$message.error('请填写必要信息')
          return false;
        }
      });
    },
    async confirmLoginByMobile() {
      if(!this.checked) {
        this.$message.error('请先同意用户协议和隐私协议');
        return;
      }
      let param = {};
      let open_id = window.sessionStorage.getItem('open_id');
      const { mobile, code } = this.loginForm;
      param["mobile"] = mobile;
      param["code"] = code;
      param['thirdId'] = open_id || ''
      const { data: res } = await loginByMobile(param);
      if(res.code == '0000') {
        const { data } = res;
        const { user } = data
        console.log('confirmLoginByMobile', data);
        this.$message.success("登录成功!");

        localStorage.setItem("token", user.sessionId)
        localStorage.setItem("user", JSON.stringify(user))
        localStorage.setItem("h5_token", data.sessionId)
        window.sessionStorage.setItem("activeMenu", '/home')
        window.sessionStorage.setItem("defaultActivename", '首页')
        //5.登录成功之后,跳转到/home页面中
        // this.$router.push("/index")
        this.$router.push({
          path: '/home',
          query: {
          }
        })
      } else {
        this.$message.error('登录失败')
      }

    },
    toRegister() {
      this.$router.push({
        path: '/register',
        query: {
        }
      })
    }

  }

}
</script>
<!-- 防止组件冲突 -->
<style lang="less" scoped>
.login {
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/imgs/login.png"); /* 修改路径以指向你的图片 */
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-repeat: no-repeat; /* 背景图片不重复 */
  //   background-position: center; /* 背景图片居中 */
  &-form {
    position: absolute;
    right: 5%;
    top: 23%;
    width: 25%;
    min-width: 425px;
    height: 50%;
    &-welcome {
      display: flex;
      align-items: center;
      justify-content: center;
      &-pic {
        margin-right: 30px;
      }
      &-desc {
        color: #8d8d8d;
      }
    }
    &-form {
      background: #fff;
      border-radius: 10px;
      margin-top: 15px;
      padding: 84px;
      &-type {
        display: flex;
        justify-content: space-between;
        color: #000;
        padding-bottom: 9px;
        cursor: pointer;
        &-choose {
          color: #4975e9;
          border-bottom: 2px solid #4975e9;
        }
      }
      &-qr {
        text-align: center;
        &-title {
          margin-top: 30px;
          color: #333;
        }
        &-desc {
          color: #999;
          margin-top: 14px;
        }
        &-pic {
          margin-top: 30px;
          //   position: relative;
          //   display: flex;
          &-logo {
            width: 75px;
            height: 75px;
            left: 85px;
            top: 85px;
            position: absolute;
          }
        }
      }
      &-content {
        &-code {
          /deep/.el-input-group__append,
          .el-input-group__prepend {
            border-left: none;
            background: #fff;
            color: #4975e9;
            .is-disabled {
              color: #8d8d8d !important;
            }
          }
        }
        &-bottom {
          margin-top: 16px;
          text-align: center;

          &-desc {
            color: #999999;
          }
          &-check {
            margin-top: 16px;
            /deep/.el-checkbox__inner {
              border-radius: 50%;
            }
            &-desc {
              color: #999999;
              &-xieyi {
                color: #333;
              }
            }
          }
        }
      }
    }
  }
}
</style>
