<template>
  <div class="qr-code">
    <canvas id="QRCode_header" ref="canvas" title="扫描二维码"></canvas>
  </div>
</template>
  
<script>
import QRCode from "qrcode"; //引入生成二维码插件
export default {
  name: 'CodeS',
  props: {
    canvasWidth: {
      default: 200,
      type: Number
    },
    canvasHeight: {
      default: 200,
      type: Number
    },
    url: {
      default: '',
      type: String,
      required: true
    },
    logoUrl: {
      default: '',
      type: String,
      // required:true
    }
  },
  watch: {
    url(val) {
      this.getQRCode();
    }
  },
  methods: {
    getQRCode() {
      let myCanvas = this.$refs.canvas
      let ctx = myCanvas.getContext('2d')
      // 在Canvas画布 添加图片
      let img = new Image()
      img.crossOrigin = 'Anonymous';//解决Canvas.toDataURL 图片跨域问题
      img.src = this.url;
      img.onload = () => {
        //第一个设置的元素，第二三是位置，后面两个是宽和高
        //居中的位置计算为 （二维码宽度-img宽度）/2
        let codeWidth = this.canvasWidth
        let codeHeight = this.canvasHeight
        ctx.drawImage(img, codeWidth, codeHeight, this.canvasWidth * 0.25, this.canvasHeight * 0.25)
      }
      this.drawLogo();
    },
    drawLogo() {
      //设置logo图标
      if(this.logoUrl != '') {
        let myCanvas = this.$refs.canvas
        let ctx = myCanvas.getContext('2d')
        // 在Canvas画布 添加图片
        let img = new Image()
        img.crossOrigin = 'Anonymous';//解决Canvas.toDataURL 图片跨域问题
        img.src = this.logoUrl;
        img.onload = () => {
          //第一个设置的元素，第二三是位置，后面两个是宽和高
          //居中的位置计算为 （二维码宽度-img宽度）/2
          let codeWidth = (this.canvasWidth * 0.75) / 2
          let codeHeight = (this.canvasHeight * 0.75) / 2
          ctx.drawImage(img, codeWidth, codeHeight, this.canvasWidth * 0.25, this.canvasHeight * 0.25)
        }
      }
    },
  },
  mounted() {
    this.getQRCode()


  },
}
</script>